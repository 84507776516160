<template>
  <el-main>
    <page-title show-back-btn />
    <div class="tabs-inner">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="企业信息" name="companyInfo">
          <ics-customer-inner :company-detail="companyDetail" :loading-detail="loading" />
        </el-tab-pane>
        <el-tab-pane label="企业资料" name="companyData">
          <ics-company-data-inner :company-data="companyData" :loading-detail="loading" />
        </el-tab-pane>
        <el-tab-pane label="尽调报告" name="investigation">
          <ics-investigation-inner :investigation-info="investigationInfo" :company-detail="companyDetail" @nextStep="nextStep" />
        </el-tab-pane>
        <el-tab-pane label="提交附件" name="files" disabled>
          <ics-attachment-inner ref="attachmentList" :firm-id="this.firmId" :view-mode="supplyView()" @submit="submitForms" @retreat="activeName = 'investigation'" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import IcsCustomerInner from './components/customer-inner'
import IcsCompanyDataInner from './components/company-data-inner'
import IcsAttachmentInner from './components/attachment-inner'
import IcsInvestigationInner from './components/investigation-inner'
export default {
  components: { IcsCustomerInner, IcsCompanyDataInner, IcsAttachmentInner, IcsInvestigationInner },
  mixins: [routeEnterMixin],
  data () {
    return {
      firmId: this.$route.query.firmId,
      firmType: this.$route.query.firmType,
      activeName: 'companyInfo',
      companyDetail: {},
      investigationInfo: {},
      companyData: {
        tableList: []
      }
    }
  },
  created () {
    if (this.firmId) {
      this.getDetail()
      this.getCompanyDataList()
    }
  },
  methods: {
    getDetail () {
      this.loading.detail = true
      this.api.admittance.getInfoFirmDetails(this.firmId).then(result => {
        const data  = result.data.data || {}
        let regAddr = []
        let workAddr = []
        if (data.regAddr) {
          regAddr = data.regAddr.split(',')
          data.usEnterpriseAddress = [regAddr[0], regAddr[1], regAddr[2], data.regAddrDetail]
        }
        if (data.workAddr) {
          workAddr = data.workAddr.split(',')
          data.dsEnterpriseAddress = [workAddr[0], workAddr[1], workAddr[2], data.workAddrDetail]
        }
        if (data.firmAccountInfo) {
          if (data.firmAccountInfo.idCardUrlZ) {
            data.idCardUrlZ = data.firmAccountInfo.idCardUrlZ
          }
          if (data.firmAccountInfo.idCardUrlF) {
            data.idCardUrlF = data.firmAccountInfo.idCardUrlF
          }
        }
        this.companyDetail = data
      }).finally(() => {
        this.loading.detail = false
      })
    },
    supplyView () {
      if (this.$route.params.editMode === 'supplement') {
        return true
      } else {
        return false
      }
    },
    getFirmInpec () {
      this.api.admittance.getFirmInspec(this.firmId).then(result => {
        const data = result.data.data
        this.investigationInfo = data || {}
      })
    },
    handleClick () {
      if (this.activeName === 'investigation') {
        this.getFirmInpec()
      }
    },
    getCompanyDataList () {
      this.loading.list = true
      this.api.admittance.firmFileList(this.firmId, this.firmType).then(result => {
        this.companyData.tableList = result.data.data || []
      }).finally(() => {
        this.loading.list = false
      })
    },
    nextStep (formData) {
      console.log(formData)
      return new Promise((resolve, reject) => {
        this.loading.submit = true
        const data = formData || {}
        const investigation = {
          firmId: this.companyDetail.firmAccountInfo.firmId,
          firmName: this.companyDetail.firmAccountInfo.firmName,
          trustworthy: data.trustworthy,
          abnormal: data.abnormal,
          implementer: data.implementer,
          reportName: data.reportName,
          reportUrl: data.reportUrl
        }
        if (this.investigationInfo || this.investigationInfo.id) {
          investigation.id = this.investigationInfo.id
        }
        this.api.admittance.saveFirmInspect(investigation).then(result => {
          if (result.data.success === true) {
            this.loading.submit = false
            this.$message.success('保存成功')
            this.activeName = 'files'
            this.handleClick()
          } else {
            this.loading.submit = false
            this.$message.error('保存失败')
          }
          resolve(result.data.data)
        }).catch(e => {
          reject(e)
        }).finally(() => {
          this.loading.submit = false
        })
      })
    },
    submitForms () {
      return new Promise((resolve, reject) => {
        const data = {
          firmId: this.companyDetail.firmAccountInfo.firmId,
          firmName: this.companyDetail.firmAccountInfo.firmName,
        }
        this.api.admittance.commitCheck(data).then(result => {
          if (result.data.success === true) {
            this.loading.submit = false
            this.$message.success('操作成功')
            this.$router.back()
          } else {
            this.$message.error(result.data.message)
          }
          resolve(result.data.data)
        }).catch(e => {
          reject(e)
        }).finally(() => {
          this.loading.submit = false
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
